.App {
  text-align: center;
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 50px; /* Adjust as needed */
  background: #000;
  color: #fff;
  display: flex;
  justify-content:flex-end;
  align-items: center;
  z-index: 2;
}

.header a {
  padding: 0 10px;
  color: #fff;
  text-decoration: none;
}

.header a:hover {
  color: #ccc;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-text h1 {
  position: absolute;
  top: 1.8vh;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: #94908a;
  font-size: 1.2em;
  background-color: rgba(0, 0, 0, 0.0);
}

/* Laptop */
@media (max-width: 1200px) {
  .overlay-text h1 {
    font-size: 1.2em;
    top: 1.8vh;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .overlay-text h1 {
    font-size: 1.2em;
    top: 1.8vh;
  }
}

/* Mobile */
@media (max-width: 500px) {
  .overlay-text h1 {
    font-size: 0.8em;
    top: 1.8vh;
  }
}