body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Diablo',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Diablo';
  src: url('../public/fonts/DiabloHeavy.woff2') format('woff2'),
      url('../public/fonts/DiabloHeavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Diablo';
  src: url('../public/fonts/DiabloLight.woff2') format('woff2'),
      url('../public/fonts/DiabloLight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
